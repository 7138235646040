import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useAuthStore } from '@/store/auth'

const routes: Array<RouteRecordRaw> = [
	{
		path: '',
		redirect: '',
		component: () => import('@/components/layouts/main.vue'),
		children: [
			{ path: '', component: () => import('@/components/pages/index/index.vue'), meta: { auth: true } },
			{ path: '/place/index', component: () => import('@/components/pages/place/index/index.vue'), meta: { auth: true } },
			{ path: '/place/page/:id?', component: () => import('@/components/pages/place/page/page.vue'), meta: { auth: true } },
			{ path: '/bank/index', component: () => import('@/components/pages/bank/index/index.vue'), meta: { auth: true } },
			{ path: '/bank/page/:id?', component: () => import('@/components/pages/bank/page/page.vue'), meta: { auth: true } }
		]
	},
	{
		path: '',
		component: () => import('@/components/layouts/empty.vue'),
		children: [
			{ path: '/auth/login', component: () => import('@/components/pages/auth/login/login.vue'), meta: { auth: false } },
			{ path: '/:catchAll(.*)*', component: () => import('@/components/pages/404/404.vue'), meta: { auth: false } }
		]
	}
]

const router = () => {
	const router = createRouter({
		history: createWebHistory(process.env.BASE_URL),
		routes
	})
	const authStore = useAuthStore()
	router.beforeEach((to, from, next) => {
		const requiredAuth = to.matched.some(r => r.meta.auth)
		const token = authStore.token
		if (requiredAuth && !token) next(`/auth/login?redirect=${to.fullPath}`)
		else next()
	})
	return router
}

export default router
